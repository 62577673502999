<template>
  <hooper :centerMode="true" :mouseDrag="false" :wheelControl="false" :autoPlay="true" :playSpeed="4000">
    <slide v-for="(slide, indx) in images" :key="indx" :index="indx">
      <a :href="slide.url" target="_blank">
        <img class="img-container" style="height:500px; width:100%"
          :src="$base_url + slide.image" :alt="slide.title" />
      </a>
    </slide>
    <!-- <slide>
     <router-link :to="'/store/vogacloset'">
      <img class="img-container" 
      style="height:500px; width:100%" 
      src="../../assets/images/banner/vogacloset-black-friday.png"
       />
     </router-link> 
     </slide> -->
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
  </hooper>
</template>

<script>
  import {
    Hooper,
    Slide,
    Navigation as HooperNavigation
  } from 'hooper';
  import 'hooper/dist/hooper.css';
  //https://baianat.github.io/hooper/api.html#props
  export default {
    name: "Slider",
    props: ['images'],
    components: {
      Hooper,
      Slide,
      HooperNavigation
    },
    methods: {
      getBannerLink(store = null) {
        if (store) {
          if (store.hasOwnProperty('store_id') && store.store_id) {
            // return `/store/${store.store_id.slug}`;
            this.redirectToStore(store.store_id.slug)
          }

          if (store.link && store.link.length > 0) {
            window.location.href = store.link;
            // this.$router.push(store.link)
          }
        }
        return store.url;
      },
      redirectToStore(slug){
          this.$router.push({ name: 'SearchStore',  params: { slug: slug }, })
      }
    }
  }
</script>